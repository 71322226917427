// src/Header.js
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import "../../styles/Header.css";
import { useLanguage } from "../LanguageContext";
const Header = () => {
  const { language, toggleLanguage } = useLanguage();
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };
  return (
    <header className="container-header">
      {/*
      <div className="social-icons">
        <div className="button-container">
          <button className="changeBttn">
           
            {/* onClick={() => toggleLanguage()} */}
      {/*
            {language === "heb" ? (
              <p>Change Language</p>
            ) : (
              <p class="hebrew-text">שנה שפה</p>
            )}
          </button>
        </div>
      </div>
*/}
      <div className="container-nav-ico">
        <Navbar />

        <img
          className="logo"
          src="invert_logo.png"
          alt="Chazon Icon"
          width="60"
          height="60"
          onClick={handleNavigation}
        />
      </div>
    </header>
  );
};

export default Header;
