import React, { useState } from "react";
import AddCardForm from "./Forms/AddCardForm";
import Button from "./Button";
import { useLanguage } from "./LanguageContext";

const AddCardButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleOpenForm = () => setIsFormOpen(true);
  const handleCloseForm = () => setIsFormOpen(false);
  const translations = {
    en: {
      label: "add new rull",
    },
    heb: {
      label: "הוסף הצעת חוק חדשה",
    },
  };
  const { language } = useLanguage();
  const { label } = translations[language];

  return (
    <div>
      <Button label={label} onClick={handleOpenForm}></Button>
      {isFormOpen && <AddCardForm onClose={handleCloseForm} />}
    </div>
  );
};

export default AddCardButton;
