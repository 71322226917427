// src/Layout.js
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../styles/Layout.css";
const Layout = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100); // Delay to make animation visible
  }, []);
  return (
    <div>
      <Header />
      <main>{children}</main>
      {/* <main className="content-container">{children}</main> */}
      <Footer />
    </div>
  );
};

export default Layout;
