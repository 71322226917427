// src/MainPage.js
import React from "react";
import DifferentImpact from "../components/Sections/DifferentImpact.js";
import PhoneSection from "../components/Sections/PhoneSection.js";
import MethodSection from "../components/Sections/MethodSection.js";
import TenCommandments from "../components/Sections/TenCommandmentsSection.js";
import ProblemSolution from "../components/Sections/ProblemSolution.js";
import Layout from "../components/Sections/Layout";
import { useLanguage } from "../components/LanguageContext";
import HeroSection from "../components/Sections/hero.js";
import HowtoJoin from "../components/Sections/HowtoJoin.js";
import "../styles/main.css";
import texts from "../data/texts.json";

function MainPage() {
  const data = texts["hebrew minister"];
  const translations = {
    en: {
      section1: "This is the first section with a dark background.",
      section2: "This is the second section with a dark blue background.",
      section3: "This is the third section with a dark green background.",
      he1: "first header",
      he2: "second header",
      he3: "third header",
    },
    heb: {
      section1:
        "לא ימין, לא שמאל ולא מרכז. מפלגת חזון-מפלגה עצמאית- " +
        " היא מפלגה ששמה לה למטרה לייצג את כל " +
        "קצוות הקשת הפוליטית ולהתרכז בעבודה בפועל שצריכה להיעשות. " +
        "כל נציג לוקח עליו חוק עליו הוא מתחייב לעבוד. כל ההחלטות מתקבלות על ידי הבוחרים בעצמם.",
      section2: Object.values(data).map((para, idx) => (
        <React.Fragment key={idx}>
          {para} <br />
          <br />
        </React.Fragment>
      )),
      section3:
        "שותפים שאינם מעוניינים להצביע בכל נושא יכולים לייפות את כוחו של שותף אחר שיצביע עבורם. כל שותף מיופה כוח מוגבל להצבעה עבור עשרה שותפים מקסימום." +
        "לדוגמא: שותף שאינו רוצה להיות מעורב בכל הצבעה או שותף שחש שהוא לא מספיק בעניינים כלפי הצבעה מסויימת, יכול למנות בן משפחה או חבר טוב שיש ביניהם יחסי אמון להצביע בעבורו. ",
      he1: "רעיון",
      he2: "שרים",
      he3: "יפוי כוח",
    },
  };

  const { language } = useLanguage();
  const { section1, section2, section3, he1, he2, he3 } =
    translations[language];

  const sections = [
    {
      text: section1,
      backgroundColor: "#333",
      h1: he1,
    },
    {
      text: section2,
      backgroundColor: "#1a1a40",
      h1: he2,
    },
    {
      text: section3,
      backgroundColor: "#004d40",
      h1: he3,
    },
  ];

  return (
    <Layout>
      <HeroSection></HeroSection>
      <DifferentImpact></DifferentImpact>
      <PhoneSection></PhoneSection>

      <TenCommandments></TenCommandments>
      <MethodSection></MethodSection>
      <ProblemSolution></ProblemSolution>
      <HowtoJoin></HowtoJoin>
    </Layout>
  );
}

export default MainPage;
