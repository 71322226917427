import React, { useState, useEffect } from "react";
import "../../styles/DifferentImpact.css";
import { useLanguage } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import InfoBox from "../InfoBox";
import SlideUpWrapper from "../SlideUpWrapper";
const DifferentImpact = () => {
  const [isPhoneView, setIsPhoneView] = useState(window.innerWidth <= 768);
  useEffect(() => {
    // Function to handle resize
    const handleResize = () => {
      setIsPhoneView(window.innerWidth <= 768);
    };
  });
  const translations = {
    en: {
      section1: "This is the first section with a dark background.",
      h1: "first header",
      section2: "This is the first section with a dark background.",
      h2: "first header",
      section3: "This is the first section with a dark background.",
      h3: "first header",
      section4: "for more info ...",
      section5: "click here",
      section: "da",
      section6: "blat",
      diff: "we diffrent",
    },
    heb: {
      section1:
        "במפלגת חזון, הבחירה נשארת בידי הבוחר – דמוקרטיה אמיתית שלא נגמרת ביום הבחירות. גם אחרי שהנציג נבחר, הוא מחויב להישאר קשוב ולפעול על פי רצון השותפים. לשם כך, נפעיל משאלים בין כל השותפים כדי להחליט יחד על כל נושא משמעותי.",
      section:
        "אנחנו מאמינים שבאמצעות כלים דיגיטליים מתקדמים, נוכל לבנות מערכת של משאלים פתוחים שמאפשרת לכל אחת ואחד מכם להביע את דעתכם ולהשפיע ישירות על ההחלטות. הנציגים שלנו לא יהיו רק אלה שמחליטים אלא אלה שמבצעים את רצון הציבור. כל צעד שהם ייקחו יהיה מבוסס על מה שהבוחרים מבקשים, כך שהכוח יישאר באמת בידיים שלכם – והדמוקרטיה תמשיך לפעול בכל יום, בכל החלטה.",
      h1: "דמוקרטיה ישירה",
      section2:
        "במפלגת חזון אין מצע פוליטי קבוע – אנחנו לא ימין, לא שמאל ולא מרכז. המטרה שלנו היא פשוטה: לקדם חוקים שיעשו טוב לאזרח הפשוט, בלי קשר לעמדות פוליטיות מקובלות. כל נציג המבקש להתמודד במסגרת המפלגה צריך להציג חוק אחד – זהו החזון האישי שלו, העיקרון שעליו הוא מתמודד ועל בסיסו נבחר. כמובן, הנציג יכול להציג רעיונות נוספים, אך כל מהלכיו צריכים להתאים לעקרונות המפלגה ולהיות מוסכמים על כלל השותפים.",
      h2: "מצע",
      section6:
        "בחזון אנו מאמינים שבדרך הזו נוכל לבנות מפלגה שבאמת מקשיבה לצרכים ולרצונות של הציבור.",
      section3:
        "הכוח מגיע מלמטה ללמעלה, הכוח האמיתי טמון בשותפי המפלגה. כל שותף מקבל הזדמנות להציע חקיקה חדשה כל זמן מסויים, מתוך אמונה בכוחו של הציבור לעצב את המציאות. בניגוד למפלגות רגילות שבהן הכוח מרוכז בידי הפוליטיקאים - פוליטיקאי בונה מפלגה והציבור בוחר בו על פי אישיותו או האג'נדה שלו - כאן התהליך הפוך. הרעיונות של שותפי המפלגה הם שמובילים, ורק לאחר מכן נבחרים הנציגים שמבצעים את אותם רעיונות בפועל.",
      section7:
        "כדי ליישם עיקרון זה, הגינו את שיטת השרים, המבטיחה שהכוח יישאר בידי שותפי המפלגה.",
      h3: "הכוח מגיע מלמטה ללמעלה",
      section4: "לעוד פרטים ...",
      section5: "לעוד פרטים לחץ כאן",
      diff: "במה אנחנו שונים",
    },
  };
  const { language } = useLanguage();
  const {
    section1,
    h1,
    section2,
    h2,
    section3,
    h3,
    section4,
    section5,
    section,
    section6,
    section7,
    diff,
  } = translations[language];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/ministers");
  };

  return (
    <div className="backround">
      <div className="content-container">
        <div className="differentImpact">
          <SlideUpWrapper>
            <h1 className="hebrew-header1-W">{diff}</h1>
          </SlideUpWrapper>
          <br></br>

          <div className="boxContainer">
            <SlideUpWrapper>
              <InfoBox title={h1} paragraphs={[section1, section]} />
            </SlideUpWrapper>
            {!isPhoneView ? (
              <SlideUpWrapper>
                <InfoBox
                  title={h2}
                  paragraphs={[section2, section6]}
                  minHeight={"430px"}
                />
              </SlideUpWrapper>
            ) : null}
          </div>

          <br></br>
          <br></br>
          <br></br>
          {isPhoneView ? (
            <div>
              <SlideUpWrapper>
                <InfoBox
                  title={h2}
                  paragraphs={[section2, section6]}
                  minHeight={"430px"}
                />
              </SlideUpWrapper>
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : null}

          <SlideUpWrapper>
            <InfoBox
              title={h3}
              paragraphs={[section3, section7]}
              ButtonComponent={
                <Button label={section5} onClick={handleNavigation} />
              }
            />
          </SlideUpWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default DifferentImpact;
