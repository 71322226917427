// /components/SearchSection.jsx
import React from "react";
import Button from "./Button";
import "../styles/SearchSection.css";
import { useLanguage } from "./LanguageContext";
const SearchSection = ({
  searchValue,
  onSearchChange,
  onSearch,
  selectedFeature,
  onFeatureChange,
  onShowAll,
}) => {
  const translations = {
    en: {
      searchLabel: "serach ...",
      buttonLabel: "serach ",
      showAllLabel: "Show All",
      option: "where to search",
      option1: "title",
      option2: "describtion",
      option3: "owner",
      option4: "topic",
    },
    heb: {
      searchLabel: "חפש...",
      buttonLabel: "חפש ",
      showAllLabel: "הצג הכל",
      option: "איפה לחפש",
      option1: "כותרת",
      option2: "תיאור",
      option3: "כותב",
      option4: "נושא",
    },
  };

  const { language } = useLanguage();
  const {
    searchLabel,
    buttonLabel,
    showAllLabel,
    option,
    option1,
    option2,
    option3,
    option4,
  } = translations[language];

  return (
    <div className={`searchSection ${language === "heb" ? "rtl" : "ltr"}`}>
      <div className="searchContainer">
        <input
          type="text"
          placeholder={searchLabel}
          value={searchValue}
          onChange={onSearchChange}
          className="searchInput"
        />
      </div>
      <div className="filterSelect">
        <select value={selectedFeature} onChange={onFeatureChange}>
          <option value="where">{option}</option>
          <option value="title">{option1}</option>
          <option value="describtion">{option2}</option>
          <option value="owner">{option3}</option>
          <option value="topic">{option4}</option>
        </select>
      </div>

      <div className="ButtonContainer">
        <div className="Button1">
          <Button onClick={onSearch} label={buttonLabel}></Button>
        </div>
        <div className="Button1">
          <Button onClick={onShowAll} label={showAllLabel}></Button>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
