// /components/Forum.jsx
import React, { useEffect, useState } from "react";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import Card from "./Card";
import "../styles/Forum.css";

const Forum = ({ filterCriteria }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchFilteredCards = async () => {
      try {
        let q;
        if (
          filterCriteria &&
          filterCriteria.searchValue &&
          filterCriteria.selectedFeature
        ) {
          q = query(
            collection(db, "laws"),
            where(
              filterCriteria.selectedFeature,
              ">=",
              filterCriteria.searchValue
            ),
            where(
              filterCriteria.selectedFeature,
              "<=",
              filterCriteria.searchValue + "\uf8ff"
            )
          );
        } else {
          q = query(collection(db, "laws"));
        }

        const querySnapshot = await getDocs(q);
        const fetchedCards = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCards(fetchedCards);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    fetchFilteredCards();
  }, [filterCriteria]);

  return (
    <div className="forum">
      {cards.map((card, index) => (
        <Card key={index} data={card} />
      ))}
    </div>
  );
};

export default Forum;
