// src/Contact.js
import React from "react";
import Layout from "../components/Sections/Layout";
import ContactForm from "../components/Forms/ContactForm";
function Contact() {
  return (
    <Layout>
      <div className="backround">
        <ContactForm></ContactForm>
      </div>
    </Layout>
  );
}

export default Contact;
