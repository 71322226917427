import React, { useState } from "react";
import { addFirestoreCard } from "../firebase";
import "../../styles/AddCardForm.css";
import { useLanguage } from "../LanguageContext";
import Button from "../Button"; // Your custom Button component

const AddCardForm = ({ onClose }) => {
  const [title, setTitle] = useState("");
  const [owner, setOwner] = useState("");
  const [description, setDescription] = useState("");
  const [topic, setTopic] = useState("");
  const translations = {
    en: {
      title1: "propose a new law",
      title2: "name of the law",
      owner1: "how propose the law",
      topic1: "law topic",
      description1: "Description",
      send: "Send",
      cancel: "cancel",
    },
    heb: {
      title1: "תציע חוק חדש",
      title2: "שם החוק",
      owner1: "מי הציע את החוק",
      topic1: "נושא החוק",
      description1: "תיאור החוק",
      send: "שלח",
      cancel: "בטל",
    },
  };
  const { language } = useLanguage();
  const { title1, title2, owner1, topic1, description1, cancel, send } =
    translations[language];
  const handleSubmit = async () => {
    if (!title || !owner || !description || !topic) {
      alert("Please fill in all fields.");
      return;
    }

    const newCard = { title, owner, description, topic };
    await addFirestoreCard(newCard);
    alert("Card added successfully!");
    onClose();
  };

  return (
    <div className="addCardForm-overlay">
      <div className="addCardForm-container">
        <h2 className="form-title">{title1}</h2>
        <div className="form-group">
          <label>{title2}</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{owner1}</label>
          <input
            type="text"
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{topic1}</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{description1}</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-actions">
          <Button label={cancel} onClick={onClose} />
          <Button label={send} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default AddCardForm;
