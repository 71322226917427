import React from "react";
import "../styles/LoadingOverlay.css"; // Add CSS for the overlay

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <img src="/loading.gif" alt="Loading..." className="loading-gif" />
    </div>
  );
};

export default LoadingOverlay;
