// src/firebase.js
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  updateDoc,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { serverTimestamp } from "firebase/firestore";
const apiKey = process.env.REACT_APP_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getFirestoreCards = async () => {
  const q = query(collection(db, "laws"), orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};
export const addFirestoreCard = async (card) => {
  await addDoc(collection(db, "laws"), {
    ...card,
    createdAt: serverTimestamp(),
  });
};

// Add a comment to the Firestore collection and update the card's comments list
export const addComment = async (cardId, comment) => {
  // Add comment to the 'comments' collection
  const commentDoc = await addDoc(collection(db, "comments"), {
    ...comment,
    cardId,
    createdAt: serverTimestamp(),
  });

  // Update the card document to include the new comment's ID
  const cardRef = doc(db, "laws", cardId);
  await updateDoc(cardRef, {
    comments: commentDoc.id,
  });

  return commentDoc.id;
};

// Fetch comments for a specific card, sorted by timestamp
export const getCommentsByCard = async (cardId) => {
  const q = query(collection(db, "comments"), orderBy("createdAt", "asc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs
    .filter((doc) => doc.data().cardId === cardId)
    .map((doc) => ({ id: doc.id, ...doc.data() }));
};
export { db };
