import React, { useState } from "react";
import CommentForm from "./Forms/CommentForm";
import Comments from "./Comments";
import "../styles/Card.css";
import Button from "./Button";
import { useLanguage } from "./LanguageContext";
const Card = ({ data }) => {
  const [isFullView, setIsFullView] = useState(false);
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);

  const toggleFullView = () => setIsFullView(!isFullView);
  const translations = {
    en: {
      addCommentLabel: "Add Comment",
      HideCommentLabel: "I Dont Want to add Comment",
      owner: "name",
      topic: "topic",
      minimize: "Minimize",
      comments: "Comments",
    },
    heb: {
      addCommentLabel: "הוסף תגובה",
      HideCommentLabel: "אל תוסיף תגובה",
      owner: "שם",
      topic: "נושא",
      minimize: "צמצם",
      comments: "תגובות",
    },
  };

  const { language } = useLanguage();
  const { addCommentLabel, HideCommentLabel, owner, topic, minimize } =
    translations[language];
  return (
    <div
      className={`card ${isFullView ? "fullView" : "smallView"}`}
      onClick={!isFullView ? toggleFullView : null}
    >
      {isFullView ? (
        <>
          <h2 className="cardHeader">{data.title}</h2>
          {/* <p>{data.owner}</p>
          <p>{data.topic}</p> */}
          <div className="descriptionFull">
            <p>{data.description}</p>
          </div>
          <div className={`moreinfo ${language === "heb" ? "rtl" : "ltr"}`}>
            <p className="moreinfoPL">{owner}:</p>
            <p className="moreinfoP">{data.owner}</p>
            <p className="moreinfoP">|</p>
            <p className="moreinfoPL">{topic}:</p>
            <p className="moreinfoP">{data.topic}</p>
          </div>
          <Button
            label={isCommentFormVisible ? HideCommentLabel : addCommentLabel}
            onClick={(e) => {
              e.stopPropagation();
              setIsCommentFormVisible(!isCommentFormVisible);
            }}
          ></Button>
          {isCommentFormVisible && (
            <CommentForm cardId={data.id} onCommentAdded={() => {}} />
          )}
          <p className="text">תגובות</p>
          <Comments cardId={data.id} />
          <div className="minimizeButton">
            <Button
              label={minimize}
              onClick={() => setIsFullView(false)}
            ></Button>
          </div>
        </>
      ) : (
        <>
          <h2 className="cardHeader">{data.title}</h2>
          <div className="description">
            <p>{data.description.slice(0, 50)}...</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
