import React from "react";
import { useLanguage } from "./LanguageContext";
import "../styles/MiniInfoBox.css";

const MiniInfoBox = ({ title, shortText, logo, ButtonComponent }) => {
  const { language } = useLanguage(); // Access the language context

  return (
    <div
      className={`miniInfoBox-layout ${
        language === "heb" ? "rtl-layout" : "ltr-layout"
      }`}
    >
      {language === "heb" ? (
        <>
          <div className="miniInfoBox-logo">{logo}</div>
          <br></br>
          <div className="miniInfoBox-text">
            <h3 className="hebrew-header2-W">{title}</h3>
            <p>{shortText}</p>
            {ButtonComponent && (
              <div className="infoBox-button-container">{ButtonComponent}</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="miniInfoBox-logo">{logo}</div>
          <div className="miniInfoBox-text">
            <h3>{title}</h3>
            <p>{shortText}</p>
            {ButtonComponent && (
              <div className="miniInfoBox-button-container">
                {ButtonComponent}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MiniInfoBox;
