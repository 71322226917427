import React from "react";
import { useLanguage } from "./LanguageContext";
import "../styles/InfoBox.css";

const InfoBox = ({ title, paragraphs = [], ButtonComponent, minHeight }) => {
  const { language } = useLanguage(); // Get the current language from the context

  return (
    <div
      className={`infoBox ${language === "heb" ? "rtl" : "ltr"}`}
      style={{ "--min-height": minHeight }}
    >
      <h3 className="hebrew-header2-W">{title}</h3>
      <div className="infoBox-content">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      {ButtonComponent && (
        <div className="infoBox-button-container">{ButtonComponent}</div>
      )}
    </div>
  );
};

export default InfoBox;
