import React, { useState, useEffect } from "react";
import "../../styles/HeroSection.css";
import { useLanguage } from "../LanguageContext";

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const translations = {
    en: {
      section1: "This is the first section with a dark background.",
      he1: "first header",
      subh: " not right not left and not middle",
      section2: "sd",
    },
    heb: {
      section1:
        "מפלגת חזון - מפלגה דמוקרטית ישירה - היא מפלגה ששמה לה למטרה לייצג את כל קצוות הקשת הפוליטית ולהתרכז בחוקים שמייצגים את העם",
      section2: "כל ההחלטות מתקבלות על ידי הבוחרים בעצמם ולא פעם בארבע שנים",

      he1: "מפלגת חזון",
      subhe: "הכוח בידיים שלנו ",
    },
  };
  const { language } = useLanguage();
  const { section1, he1, subhe, section2 } = translations[language];
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100); // Delay for fade-in
  }, []);
  return (
    <div
      className={`hero2 ${isVisible ? "fade-in" : ""}`}
      style={{
        backgroundImage: "url('/hero.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: "-1",
      }}
    >
      <div className="textbox-container">
        <div className="content-container">
          <div className="heroTextBox">
            <h1 className="hebrew-header1-W ">{he1}</h1>
            <h3 className="hebrew-header2-W ">{subhe}</h3>
            <p className="heroDescription">
              {section1}
              <div className="custom-divider"></div>
              {section2}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
