import React from "react";
import "../styles/Popup.css";
import Button from "./Button"; // Import your custom Button component

const Popup = ({ title, info, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2 className="popup-title">{title}</h2>
        <p className="popup-info">{info}</p>
        <div className="popup-button-container">
          <Button label="OK" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default Popup;
