import React, { useState } from "react";
import { addComment } from "../firebase";
import { useNavigate } from "react-router-dom";
import "../../styles/CommentForm.css";
import "../../styles/main.css";
import Button from "../Button";
const CommentForm = ({ cardId, onCommentAdded }) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (!name || !text) {
      alert("Please fill in all fields.");
      return;
    }

    const comment = { name, text };
    const commentId = await addComment(cardId, comment);

    onCommentAdded(commentId); // Notify parent to refresh comments
    setName(""); // Reset form
    setText("");
    navigate("/forum");
  };

  return (
    <div className="backround">
      <div className="commentForm">
        <h3>הוסף תגובה</h3>
        <div className="formGroup">
          <label>שם</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="formGroup">
          <label>תגובה</label>
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></textarea>
        </div>
        <Button
          label={"שלח"}
          onClick={handleSubmit}
          className="submitButton"
        ></Button>
      </div>
    </div>
  );
};

export default CommentForm;
