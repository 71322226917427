import React from "react";
import { useLanguage } from "../LanguageContext";
import InfoBox from "../InfoBox";
import arrowAnimation from "../../assets/arrow.gif";
import SlideInWrapper from "../SlideInWrapper";
import "../../styles/Problem.css";
const ProblemSolution = () => {
  const translations = {
    en: {
      title: "The Ten Commandments",
      h1: "",
      text1: "",
      h2: "",
      text2: "",
      h3: "",
      text3: "",
      h4: "",
      text4: "",
      h5: "",
      text5: "",
      h6: "",
      text6: "",
      h7: "",
      text7: "",
      h8: "",
      text8: "",
    },
    heb: {
      title: "בעיות והדרך שלנו לפתרון ",
      h1: "אין אמון בנבחרי הציבור",
      text1:
        "היעדר האמון בין הציבור לנבחריו הוא אחת הבעיות המרכזיות בדמוקרטיה המודרנית. המערכת הקיימת מאפשרת לנבחרי הציבור לקבל את אמון הבוחר אחת לארבע שנים, אך לאחר הבחירות אין להם מחויבות אמיתית להקשיב לבוחר או לפעול בהתאם לרצונותיו. דוגמאות רבות מצביעות על כך שמנהיגים פועלים בניגוד להבטחותיהם או עוסקים בנושאים שלא הוזכרו בקמפיין הבחירות שלהם. מצב זה יוצר תחושת ניתוק ואכזבה עמוקה בקרב האזרחים.",
      h2: "חיזוק האמון באמצעות בחירות רציפות",
      text2:
        "אנו מאמינים שהדרך לשקם את אמון הציבור היא לאפשר מעורבות מתמשכת בקבלת ההחלטות. בעידן הטכנולוגי, אין סיבה שהציבור יוכל להשפיע רק אחת לארבע שנים. אנו מציעים פתרונות טכנולוגיים שיאפשרו לציבור להצביע ולקחת חלק בהחלטות מרכזיות גם במהלך כהונת הממשלות. למשל, בנושאים שנויים במחלוקת כמו חקיקה משמעותית או אישור תקציבי ענק, הציבור יוכל להביע את דעתו בזמן אמת. כך ניתן להבטיח שהמנהיגים מחויבים להקשיב לרצון הציבור לכל אורך כהונתם ולא רק בעת הבחירות.",
      h3: `אחוז החסימה – מחסום בפני יוזמות חדשות`,
      text3: `אחוז החסימה הגבוה במדינת ישראל מהווה מכשול משמעותי לחדשנות פוליטית ולרעיונות פורצי דרך. כדי להעביר רעיון ייחודי, נדרש יוזם הרעיון לצבור הון פוליטי עצום ולהקים מפלגה שלמה סביבו, גם אם מדובר בנושא אחד בלבד. דוגמה בולטת לכך היא מפלגת "עלה ירוק”, שניסתה לקדם את נושא הלגליזציה, אך לא הצליחה לעבור את אחוז החסימה הגבוה. אולם "עלה ירוק" לא רצו בבחירות של 2022 אבל אם כן היו נדרשים 154,854 קולות כדי לעבור את אחוז החסימה – דרישה זו מונעת מאנשים מוכשרים להציג רעיונות בודדים ללא הצורך בארגון פוליטי רחב.`,
      h4: "אחוז החסימה ויכולת שיתוף פעולה בין רעיונות שונים",
      text4:
        "אנו שואפים לשנות את אופן הכניסה של רעיונות לזירה הפוליטית, באמצעות יצירת אפשרות למספר יוזמות ואג'נדות לשתף פעולה במסגרת מפלגה אחת, גם אם הן מגיעות ממחנות אידיאולוגיים שונים. לפי חזוננו, כל נציג מביא עמו חוק מרכזי שברצונו לקדם ואת ציבור המצביעים התומכים בו. בדרך זו, אין צורך לעמוד באחוז החסימה על בסיס רעיון אחד בלבד, אלא ניתן לאגד מספר רעיונות מגוונים תחת מפלגה משותפת, המקדמת אג'נדות רחבות וכוללות.",
      h5: "חוסר היכרות עם נבחרי הציבור במפלגות הגדולות",
      text5:
        "אנו מאמינים שכל נבחר ציבור צריך להגדיר לעצמו חזון ברור – חוק מרכזי שהוא מתחייב לקדם במהלך כהונתו. כך, הציבור יוכל לעקוב אחר ביצועיו ולדעת האם הוא עמד במשימתו או נכשל. לדוגמה, נציג יוכל להתחייב לקדם חוק להגדלת מספר הדירות בהגרלה של המחיר למשתכן. שקיפות כזו תאפשר לציבור למדוד את ההצלחה של הנציגים על בסיס הבטחותיהם, ולא על פי רטוריקה פוליטית בלבד. אנו מציעים מערכת שקופה שבה הציבור יוכל לעקוב בזמן אמת אחר התקדמות החזון של כל נציג.",
      h6: "חזון אישי לכל נציג",
      text6:
        "אנו מאמינים שכל נבחר ציבור צריך להגדיר לעצמו חזון ברור – חוק מרכזי שהוא מתחייב לקדם במהלך כהונתו. כך, הציבור יוכל לעקוב אחר ביצועיו ולדעת האם הוא עמד במשימתו או נכשל. לדוגמה, נציג יוכל להתחייב לקדם חוק להגדלת מספר הדירות בהגרלה של המחיר למשתכן. שקיפות כזו תאפשר לציבור למדוד את ההצלחה של הנציגים על בסיס הבטחותיהם, ולא על פי רטוריקה פוליטית בלבד. אנו מציעים מערכת שקופה שבה הציבור יוכל לעקוב בזמן אמת אחר התקדמות החזון של כל נציג.",
      h7: "בעיית השקיפות בשימוש בכספי ציבור",
      text7:
        "אנו רואים בשקיפות בנושא השימוש בכספי הציבור ערך עליון, אך למרבה הצער, המערכת הפוליטית בישראל סובלת מחוסר שקיפות משמעותי בתחום זה. מפלגות רבות מקבלות מימון ציבורי המיועד לפעילותן השוטפת, אך הציבור אינו יודע כיצד הכספים הללו מנוצלים בפועל. דוגמה לכך היא הביקורת על כך שמפלגות משתמשות בכספים למטרות שאינן תואמות את ייעודם, כגון קידום אינטרסים פרטיים, מימון קמפיינים שנויים במחלוקת, או תשלומים לגורמים חיצוניים שאינם מדווחים באופן מספק. מצב זה יוצר תחושת חוסר אמון עמוקה בקרב האזרחים. ",
      h8: "שקיפות מלאה בשימוש בכספי ציבור",
      text8:
        "אנו מציעים מנגנון שיבטיח שקיפות מוחלטת בשימוש בכספי ציבור על ידי מפלגות. במסגרת זו, יפורסם מסמך פורמלי אחת לתקופה, שיכלול פירוט מלא של ההוצאות וההכנסות של המפלגה. הציבור יוכל לראות כיצד המפלגה מנצלת את הכספים – החל מהוצאות על קמפיינים ועד משכורות או תשלומים לספקים חיצוניים. לדוגמה, אם מפלגה מקבלת מימון עבור קידום אג'נדה מסוימת, הציבור יוכל לבדוק האם הכסף אכן שימש לקידום מטרות אלו. שקיפות כזו לא רק שתחזק את האמון, אלא גם תבטיח שהשימוש בכספים נעשה באופן יעיל ואחראי.",
      h9: "יחסי הון-שלטון",
      text9:
        "קשרים בין בעלי הון לבין גורמי שלטון יוצרים מצב שבו אינטרסים פרטיים של תאגידים ואנשי עסקים עשירים מקבלים עדיפות על פני טובת הציבור הרחב. זה מוביל לשחיתות, חקיקה שמקדמת רווחים פרטיים במקום צרכים חברתיים, ולפגיעה באמון הציבור במערכת הפוליטית.",
      h10: "מפלגת חזון ",
      text10:
        "במפלגת חזון, ההחלטות מתקבלות בשיתוף מלא של השותפים, באופן שקוף ופתוח. מודל זה מבטיח שמקבלי ההחלטות מייצגים את רצון הציבור בלבד ולא נתונים להשפעה של בעלי הון או אינטרסים חיצוניים. כך נשמרת המחויבות לטובת הכלל ונמנעת השפעת ההון על השלטון.",
    },
  };

  const { language } = useLanguage();
  const {
    title,
    h1,
    text1,
    h2,
    text2,
    h3,
    text3,
    h4,
    text4,
    h5,
    text5,
    h6,
    text6,
    h7,
    text7,
    h8,
    text8,
    h9,
    text9,
    h10,
    text10,
  } = translations[language];

  return (
    <div className="backround">
      <div className="content-container">
        <div className="methodSection">
          <div className="header-container">
            <h1 className="hebrew-header1-W">{title}</h1>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <SlideInWrapper direction="right">
            <div className="boxContainer1">
              <InfoBox title={h1} paragraphs={[text1]} />
              <div className="img-container">
                <img
                  className="rotated-image"
                  src={arrowAnimation}
                  alt="arrow animation"
                ></img>
              </div>
              <InfoBox title={h2} paragraphs={[text2]} />
            </div>
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <SlideInWrapper direction="right">
            <div className="boxContainer1">
              <InfoBox title={h3} paragraphs={[text3]} />
              <div className="img-container">
                <img
                  className="rotated-image"
                  src={arrowAnimation}
                  alt="arrow animation"
                ></img>
              </div>
              <InfoBox title={h4} paragraphs={[text4]} />
            </div>
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <SlideInWrapper direction="right">
            <div className="boxContainer1">
              <InfoBox title={h5} paragraphs={[text5]} />
              <div className="img-container">
                <img
                  className="rotated-image"
                  src={arrowAnimation}
                  alt="arrow animation"
                ></img>
              </div>
              <InfoBox title={h6} paragraphs={[text6]} />
            </div>
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <SlideInWrapper direction="right">
            <div className="boxContainer1">
              <InfoBox title={h7} paragraphs={[text7]} />
              <div className="img-container">
                <img
                  className="rotated-image"
                  src={arrowAnimation}
                  alt="arrow animation"
                ></img>
              </div>
              <InfoBox title={h8} paragraphs={[text8]} />
            </div>
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <SlideInWrapper direction="right">
            <div className="boxContainer1">
              <InfoBox title={h9} paragraphs={[text9]} minHeight={"200px"} />
              <div className="img-container">
                <img
                  className="rotated-image"
                  src={arrowAnimation}
                  alt="arrow animation"
                ></img>
              </div>
              <InfoBox title={h10} paragraphs={[text10]} minHeight={"200px"} />
            </div>
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default ProblemSolution;
