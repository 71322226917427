// src/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";

function Navbar() {
  const { toggleLanguage } = useLanguage();

  const translations = {
    en: {
      firstTitle: "HOME",
      secoundTitle: "ABOUT",
      thirdTitle: "CONTACT",
      fourthTitle: "your vision",
      fiveTitle: " transparecy",
    },
    heb: {
      firstTitle: "בית",
      secoundTitle: "על הפרויקט",
      thirdTitle: "בואו להיות שותפים",
      fourthTitle: "החזון שלכם",
      fiveTitle: "שקיפות",
    },
  };
  const { language } = useLanguage();
  const { firstTitle, secoundTitle, thirdTitle, fourthTitle, fiveTitle } =
    translations[language];
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <nav>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      <ul className={`nav-links ${menuOpen ? "show" : ""}`}>
        {language === "heb" ? (
          <>
            <li>
              <Link className="hebrew-text-blue" to="/contact">
                {thirdTitle}
              </Link>
            </li>
            <li>
              <Link className="hebrew-text" to="/transparency">
                {fiveTitle}
              </Link>
            </li>
            <li>
              <Link className="hebrew-text" to="/forum">
                {fourthTitle}
              </Link>
            </li>

            <li>
              <Link className="hebrew-text" to="/about">
                {secoundTitle}
              </Link>
            </li>
            <li>
              <Link className="hebrew-text" to="/">
                {firstTitle}
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/">{firstTitle}</Link>
            </li>
            <li>
              <Link to="/about">{secoundTitle}</Link>
            </li>
            <li>
              <Link to="/contact">{thirdTitle}</Link>
            </li>
            <li>
              <Link to="/forum">{fourthTitle}</Link>
            </li>
            <li>
              <Link to="/transparency">{fiveTitle}</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
