import React, { useState, useEffect, useRef } from "react";
import "../styles/SlideUpWrapper.css"; // Ensure your CSS file is imported

const SlideUpWrapper = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;

      if (isInView) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`slide-up-container ${isVisible ? "visible" : ""}`}
    >
      {children}
    </div>
  );
};

export default SlideUpWrapper;
