import React from "react";
import "../styles/TextPage.css";
import { useLanguage } from "../components/LanguageContext";
import Layout from "../components/Sections/Layout";

const About = () => {
  const translations = {
    en: {
      bigHeader: "About Us",
      smallHeader1: "Our Vision",
      paragraph1:
        "We believe in creating a brighter future by empowering individuals through transparency, collaboration, and innovation.",
      smallHeader2: "Our Mission",
      paragraph2:
        "Our mission is to lead with integrity, bringing people together to achieve common goals and create meaningful change.",
    },
    heb: {
      bigHeader: "מפלגת חזון - חזון ודמוקרטיה",
      smallHeader1: "איך נולד הרעיון של מפלגת חזון?",
      paragraph1:
        "כבר הרבה זמן אני שואל את עצמי: איך זה יכול להיות שהדמוקרטיה לא התקדמה כמעט בכלל? בעידן שבו הטכנולוגיה שינתה את חיינו מהקצה לקצה – באינטרנט אנחנו יכולים לקנות, ללמוד, לקבל שירותי רפואה, ואפילו לכתוב טקסט כמו זה בעזרת בינה מלאכותית – רק הדמוקרטיה נשארה מאחור. אנחנו עדיין בוחרים נציגים פעם בכמה שנים ואז נשארים חסרי השפעה אמיתית על ההחלטות שהן הכי קריטיות לחיינו. מאז שאני זוכר את עצמי, אני מצליח לחשוב רק על חוק אחד שבאמת שינה את החיים שלנו – חוק הוזלת מחירי הסלולר של משה כחלון. זה היה רגע שהראה לי מה קורה כשמקבלים החלטה אמיצה ומשמעותית לטובת האזרח הפשוט. אבל רגעים כאלה נדירים מדי. כאן נולד הרעיון של מפלגת חזון: מפלגה שאין לה שיוך לימין או לשמאל, וכל נבחר בה מגיע עם חוק אחד ברור ומשמעותי שאותו הוא מתחייב לקדם – חוק שיכול לשנות את חייהם של האזרחים הפשוטים לטובה",
      bigHeader1: "מפלגת חזון - מערכת שרי העשרות, החמישים, המאות והאלפים",
      smallHeader2:
        "מאיפה מגיע הרעיון למבנה של שרי העשרות, החמישים, המאות והאלפים?",
      paragraph2:
        "ההשראה למבנה המיוחד של מפלגת חזון מגיעה מסיפור תנ’’כי. כאשר בני ישראל יצאו ממצרים וקיבלו את התורה, נוצר עומס עצום של שאלות ופניות בכל תחומי החיים. משה רבינו, שהיה המנהיג, נאלץ להכריע לבד בכל נושא – דבר שהפך את התהליך למעיק ולא יעיל.",
      paragraph3:
        "כשהגיע יתרו, חותנו של משה רבינו, הוא הציע להקים מערכת של היררכיה וקבוצות:",
      paragraph4: "שרי עשרות – קבוצות קטנות של עשרה אנשים עם מנהיג",
      paragraph5: "שרי חמישים – קבוצות רחבות יותר של חמישים איש",
      paragraph6: "שרי מאות  - קבוצות רחבות יותר של מאה איש",
      paragraph7: "שרי אלפים – קבוצות רחבות יותר של אלף איש",
      paragraph8:
        "כאשר אדם היה נתקל בשאלה היה עובר משר העשרות עד לאלפים עד שיקבל תשובה ואם לא קיבל תשובה רק אז פנה למשה רבינו וכך יתרו פתר את העומס למערכת ",
      paragraph9:
        "גיסי האהוב, חיים אברהם, הוא שהעניק לי את ההשראה לקחת את העקרונות העמוקים של מערכת היררכית זו ולהתאימם לעולם המודרני. במחשבה יצירתית ובחזון רחב, הוא הציע לראות את המערכת הזו לא רק ככלי ארגוני או היסטורי, אלא כמודל המאפשר שיפור ושינוי חברתי. ",
      smallHeader3: "מה זה נותן לנו במפלגת חזון?",
      paragraph95:
        "המערכת הזו במפלגת חזון מבוססת על אותם עקרונות: סדר, היררכיה ותחושת שייכות. יש לה שני יתרונות מרכזיים:",
      paragraph10:
        "סינון יעיל של הצעות - לא כל הצעה מגיעה מיד לדיון של כל השותפים. ההצעות נבחנות בהדרגה – מהקבוצות הקטנות (שרי העשרות)    ועד הקבוצות הגדולות (שרי האלפים). רק הצעות שהצליחו לעבור את כל השלבים מקבלות דיון רחב מול כלל השותפים.    בצורה הזו אנו חוסכים זמן יקר, מבטיחים איכות וממוקדים רק בהצעות הטובות ביותר.",
      paragraph11: `תחושת שייכות אמיתית- המבנה מזכיר פרלמנט שבו לכל ישראלי יש את "החבורה שלו" – בדיוק כמו השיחות הפוליטיות עם החברים שאנחנו כל כך אוהבים. אבל כאן, זה לא נגמר בדיבורים – כאן יש כוח אמיתי להשפיע.במקום להיות קול בודד במערכת ענקית, כל שותף הוא חלק מקבוצה קטנה שבה הוא יכול להשמיע את קולו, להשפיע באמת ולהיות חלק מתהליך שמוביל לשינוי אמיתי.`,
      smallHeader4: "המסקנה",
      paragraph13:
        "מפלגת חזון לוקחת את העקרונות הנצחיים של היררכיה, סדר ושייכות, ומיישמת אותם בצורה מודרנית וחדשנית כדי להבטיח דמוקרטיה אפקטיבית, יעילה ומחוברת לכל שותף",
      smallHeader5: "למה חשוב שיהיה נציג צעיר ?",
      paragraph14:
        "העולם משתנה בקצב מהיר – יוקר הדיור, טכנולוגיה, תעסוקה וחינוך – והצעירים הם אלו שמתמודדים עם האתגרים האלה מדי יום. נציג מתחת לגיל 35 מבטיח רעיונות חדשים וחשיבה רעננה, ומביא ייצוג אמיתי לדור הצעיר, זה שצריך לראות עתיד ארוך טווח במדינה.",
      paragraph15:
        "כאשר אנו בוחרים נציג מתחת לגיל 35, אנחנו לא רק מספקים ייצוג לדור הצעיר, אלא גם יוצרים דור חדש של מנהיגים חזקים ומנוסים. זהו דור שמכיר מקרוב את המציאות היומיומית של הצעירים, מבין את הצרכים והשאיפות שלהם, ומסוגל להוביל שינוי אמיתי שיבטיח מדינה מתקדמת, חדשנית , מאוחדת ומותאמת לדורות הבאים.",
      smallHeader6: "למה לתת לאזרח הפשוט כוח החלטה?",
      paragraph16:
        "יש מי שאומרים: ‘’זה מסוכן לתת לאזרח הפשוט לקבל החלטות גדולות, הוא עלול לטעות.’’ ואני מבין את החשש. אבל אני מעדיף לעשות טעות שאני אחראי לה, טעות שנעשתה מתוך כוונה אמיתית, מאשר לשלם על טעויות של מישהו אחר שפועל מתוך אינטרסים, לחצים או שיקולים זרים.טעויות הן חלק מהחיים, אבל כשאנחנו אחראים להחלטות שלנו – יש לנו יותר אחריות, יותר מוטיבציה לתקן, ולפעול להצלחה. במפלגת חזון אנחנו מאמינים בכוח של האזרח הפשוט. כשנותנים לכל אחד קול והשפעה אמיתית – אפשר לבנות מדינה טובה וצודקת יותר לכולם.",
      paragraph17:
        "אי אפשר להתעלם מהמודל של המפלגות החרדיות. הן תמיד שואפות להיות חלק מהקואליציה, ומשם מצליחות להשפיע ולהשיג הישגים משמעותיים לציבור שלהן. המפלגות החרדיות מהוות דוגמה מצוינת, מכיוון שאין להן אג'נדה ביטחונית או כלכלית מסוימת – הן מתמקדות בקידום האג'נדה הדתית שלהן. כך גם אנחנו במפלגת חזון. המטרה שלנו היא להיכנס לכנסת ולפעול לקידום חוקים משמעותיים לטובת הציבור, בלי קשר לשאלה האם השלטון הוא ימין או שמאל. האינטרס שלנו הוא האזרח הפשוט והשגת שינויים אמיתיים שישפיעו על חייו לטובה.",
      smallHeader7: "למה תמיד לשבת בקואליציה",
      smallHeader8: "האיום הגדול ביותר על מדינת ישראל– פילוג פנימי",
      paragraph18:
        "בעוד שמדינת ישראל מתמודדת עם איומים חיצוניים משמעותיים – כמו איראן, חיזבאללה, חמאס, ואיומים ביטחוניים נוספים – לדעתי, האיום האמיתי והמסוכן ביותר הוא הפילוג הפנימי בתוך העם.",
      paragraph19:
        "המתח בין קבוצות שונות בחברה הישראלית הולך וגובר, כשהפוליטיקה והתקשורת לעיתים קרובות מחזקות את הפערים הללו במקום לגשר עליהם. שיח של שנאה, חוסר סובלנות וחלוקה למחנות אידיאולוגיים יוצרים קרעים שמאיימים על הלכידות החברתית ועל עצם היכולת של המדינה לתפקד כראוי.",
      smallHeader9: "איך מפלגת חזון יכולה לקדם אחדות במקום פילוג",
      paragraph20:
        "1. שיתוף שותפים בהחלטות: במקום קבלת החלטות שמובילות לפילוג, חזון מאפשרת לכל השותפים להשתתף בתהליך קבלת ההחלטות. כאשר כל קול נשמע וכל שותף מרגיש שהוא חלק מהשיח, נוצר בסיס של אחדות ושיתוף פעולה.",
      paragraph21:
        "2. התמקדות בנושאים משותפים: במקום לעסוק בפוליטיקה מפלגתית של ימין ושמאל, חזון מתמקדת בנושאים קונקרטיים שיכולים לחבר בין כלל אזרחי ישראל, כמו חינוך, ביטחון, בריאות וכלכלה, שמעסיקים את כולם.",
      paragraph22:
        "3. קידום שיח מכבד ומאחד: המפלגה מעודדת שיח שמטרתו להבין ולהקשיב לצד השני, ולא לתקוף אותו. היא שמה דגש על יצירת שיתופי פעולה בין מגזרים שונים, מתוך הבנה שכולם רוצים בטובת המדינה.",
      paragraph23:
        "4. ייצוג מגוון: חזון פועלת לייצוג הולם של כלל קבוצות האוכלוסייה – יהודים וערבים, חילונים ודתיים, תושבי פריפריה ומרכז – כדי שכל אחד ירגיש שמקומו שמור בשולחן קבלת ההחלטות.",
      paragraph24:
        "5. לשנות את השיח הפוליטי בישראל:אנחנו מדינה מתקדמת ומובילה בעולם בתחומים כמו רפואה, ביטחון, הייטק ועוד. אך בתחום הפוליטיקה, נדמה לעיתים שאנחנו תקועים בגישה מיושנת של ''רק לא הצד השני'' או ''רק כן הצד הזה''. חזון מאמינה שהגיע הזמן להשאיר את השיח הישן מאחור, לפתוח דף חדש ולהתקדם לעבר פוליטיקה שמתמקדת בפתרונות ובשיתוף פעולה למען עתיד טוב יותר.",
      paragraph25:
        "באמצעות הגישה הייחודית שלה, חזון יוצרת מסגרת פוליטית שמחברת את העם, מציעה דרך חדשה ושואפת לעתיד מאוחד ומתקדם.",
      smallHeader10: "האם אתה רוצה להיכנס לפוליטיקה?",
      paragraph26:
        "האמת היא שלא. אני לא רוצה להיכנס לפוליטיקה, ואני גם לא חושב שיש לי כרגע את היכולות והכלים לתפקיד כזה. אבל יש לי המון רעיונות לחוקים שיכולים לקדם את החיים של האזרח הפשוט, ואני רוצה לנסות לדחוף אותם קדימה. במפלגת חזון, אני שותף בדיוק כמו כל שותף אחר. אני לא מעל אף אחד ואין לי תפקיד מיוחד. כמו כל השותפים, גם אני רוצה לראות את הרעיונות הטובים הופכים למציאות.",
      smallHeader11: "מה אני מחפש ?",
      paragraph27:
        "•	שותפות עם אנשים אופטימיים שאוהבים את הרעיון: אני מזמין כל מי שמתחבר לרעיון של המפלגה ורואה בה חזון שמבטא את השאיפות והערכים שלו, להצטרף אלינו. המפלגה היא בית לכל מי שמאמין שניתן ליצור שינוי אמיתי באמצעות שיח פתוח, שקיפות, ושימוש בכלים מודרניים. בואו להיות חלק מקהילה שואפת וצומחת שמבוססת על ערכים משותפים.",
      paragraph28:
        "•	הובלת היוזמה קדימה: אני פונה אליכם, האנשים שרוצים יותר מלהתחבר לרעיון – אלה שרוצים להיות פעילים, לקחת אחריות, ולדחוף את המפלגה קדימה. בואו נבנה יחד תשתית חזקה, נקדם יוזמות ונפעל בשטח כדי להפוך את החזון שלנו למציאות. אני מחפש אנשים שרוצים להיות שותפים אמיתיים לתהליך ולתת מהכישורים, הזמן והרצון שלהם כדי להוביל שינוי אמיתי.",
      itrotext:
        "שמי אריה שליידר בן 37, יזם וכבר שנים שאני נושא בלבי חזון לחברה שבה לכל אזרח יש קול אמיתי.",
      smallHeader12: "מי אני?",
    },
  };

  const { language } = useLanguage();
  const {
    bigHeader,
    itrotext,
    bigHeader1,
    bigHeader2,
    smallHeader1,
    smallHeader2,
    smallHeader3,
    smallHeader4,
    smallHeader5,
    smallHeader6,
    smallHeader7,
    smallHeader8,
    smallHeader9,
    smallHeader10,
    smallHeader11,
    smallHeader12,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
    paragraph6,
    paragraph7,
    paragraph8,
    paragraph9,
    paragraph10,
    paragraph11,
    paragraph13,
    paragraph14,
    paragraph15,
    paragraph16,
    paragraph17,
    paragraph95,
    paragraph18,
    paragraph19,
    paragraph20,
    paragraph21,
    paragraph22,
    paragraph23,
    paragraph24,
    paragraph25,
    paragraph26,
    paragraph27,
    paragraph28,
  } = translations[language];

  return (
    <Layout>
      <div className="backround">
        <div className="content-container">
          <h1 className="hebrew-header1-W">{bigHeader}</h1>
          <h2 className="hebrew-header2-W">{smallHeader12}</h2>

          <p className="paragraph">{itrotext}</p>
          <h2 className="hebrew-header2-W">{smallHeader1}</h2>

          <p className="paragraph">{paragraph1}</p>
          <br></br>
          <br></br>
          <h1 className="hebrew-header1-W">{bigHeader1}</h1>
          <h2 className="hebrew-header2-W">{smallHeader2}</h2>
          <p className="paragraph">{paragraph2}</p>
          <p className="paragraph">{paragraph3}</p>
          <p className="paragraph">{paragraph4}</p>
          <p className="paragraph">{paragraph5}</p>
          <p className="paragraph">{paragraph6}</p>
          <p className="paragraph">{paragraph7}</p>
          <p className="paragraph">{paragraph8}</p>
          <p className="paragraph">{paragraph9}</p>
          <h2 className="hebrew-header2-W">{smallHeader3}</h2>
          <p className="paragraph">{paragraph95}</p>
          <p className="paragraph">{paragraph10}</p>
          <p className="paragraph">{paragraph11}</p>
          <h2 className="hebrew-header2-W">{smallHeader4}</h2>
          <p className="paragraph">{paragraph13}</p>
          <h2 className="hebrew-header2-W">{smallHeader5}</h2>
          <p className="paragraph">{paragraph14}</p>
          <p className="paragraph">{paragraph15}</p>
          <h2 className="hebrew-header2-W">{smallHeader6}</h2>
          <p className="paragraph">{paragraph16}</p>
          <h2 className="hebrew-header2-W">{smallHeader7}</h2>
          <p className="paragraph">{paragraph17}</p>
          <h2 className="hebrew-header2-W">{smallHeader8}</h2>
          <p className="paragraph">{paragraph18}</p>
          <p className="paragraph">{paragraph19}</p>
          <h2 className="hebrew-header2-W">{smallHeader9}</h2>
          <p className="paragraph">{paragraph20}</p>
          <p className="paragraph">{paragraph21}</p>
          <p className="paragraph">{paragraph22}</p>
          <p className="paragraph">{paragraph23}</p>
          <p className="paragraph">{paragraph24}</p>
          <p className="paragraph">{paragraph25}</p>
          <h2 className="hebrew-header2-W">{smallHeader10}</h2>
          <p className="paragraph">{paragraph26}</p>
          <h2 className="hebrew-header2-W">{smallHeader11}</h2>

          <p className="paragraph">{paragraph27}</p>
          <p className="paragraph">{paragraph28}</p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
