// /pages/ForumPage.jsx
import React, { useState } from "react";
import SearchSection from "../components/SearchSection";
import AddCardButton from "../components/AddCardButton";
import Forum from "../components/Forum";
import "../styles/ForumPage.css";
import Layout from "../components/Sections/Layout";
const ForumPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("where");
  const [filterCriteria, setFilterCriteria] = useState({}); // Store search filters

  const handleSearchChange = (e) => setSearchValue(e.target.value);
  const handleFeatureChange = (e) => setSelectedFeature(e.target.value);
  const handleSearch = () => {
    if (searchValue && selectedFeature !== "where") {
      setFilterCriteria({ searchValue, selectedFeature });
    } else {
      alert("Please select a feature and enter a search term.");
    }
  };
  const handleAddCard = () => {
    console.log("Add Card");
  };
  const handleShowAll = () => {
    setSearchValue(""); // Reset search value
    setSelectedFeature("where"); // Reset selected feature
    setFilterCriteria({}); // Clear filters to show all cards
  };
  return (
    <Layout>
      <div className="backround">
        <div className="forum-page">
          <div className="top">
            <div className="interdaction">
              <h1 className="hebrew-header1-W">החזון שלכם</h1>
              <p className="text-int">
                נשמח לשמוע את מחשבותיכם, רעיונותיכם והצעותיכם לחוקים שיכולים
                לעזור לנו לבנות עתיד טוב יותר. בחלק זה תוכלו להציע רעיונות חדשים
                לחוקים, לעיין בהצעות שפורסמו על ידי אחרים, להביע את דעתכם,
                ולהשתתף בדיונים שמטרתם לקדם אותנו כקהילה. יחד, נוכל ליצור שינוי
                משמעותי!
              </p>
            </div>

            <div className="search-section">
              <SearchSection
                earchValue={searchValue}
                onSearchChange={handleSearchChange}
                selectedFeature={selectedFeature}
                onFeatureChange={handleFeatureChange}
                onSearch={handleSearch}
                onShowAll={handleShowAll}
              />
            </div>
            <div className="addCardSection">
              <AddCardButton onAddCard={handleAddCard} />
            </div>
          </div>
          <div className="forum-section">
            <h3 className="hebrew-header1-W">הצעות חוק של שותפים</h3>
            <Forum filterCriteria={filterCriteria} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForumPage;
