import React from "react";
import "../../styles/Footer.css";

const Footer = () => {
  return (
    <div className="backround1">
      <footer className="footerContainer">
        <div className="footerContent">
          <p>&copy; Powerd by Alexander Savizky</p>
          <p></p>
          <p>
            Contact us at{" "}
            <a href="mailto:alexsavizy@gmail.com">alexsavizy@gmail.com</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
