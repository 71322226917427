import React from "react";
import { useLanguage } from "../LanguageContext";
import MiniInfoBox from "../MiniInfoBox";
const HowtoJoin = () => {
  const translations = {
    en: { h1: "", p1: "", p2: "", p3: "" },
    heb: {
      h1: "איך נהיים שותפים?",
      p1: " הצטרפות דרך הפלטפורמה המקוונת של המפלגה, כולל מסירת פרטים ואישור התחייבות לערכי המפלגה.",
      p2: `תשלום אגרה ראשונית של 18 ש"ח למימון פעילות המפלגה, עם שקיפות מלאה על השימוש בכספים.`,
      p3: " השתתפות בהצבעות, דיונים וקבלת החלטות בנוגע למדיניות ולחוקים שהמפלגה מקדמת.",
      title1: "הרשמה רשמית",
      title2: "אגרה סמלית ",
      title3: "מעורבות פעילה",
    },
  };
  const { language } = useLanguage();
  const { h1, p1, p2, p3, title1, title2, title3 } = translations[language];
  return (
    <div className="backround2">
      <div className="content-container">
        <div className="TenCommandmentSection">
          <h1 className="hebrew-header1-W">{h1}</h1>
          <br></br>
          <br></br>
          <br></br>
          <MiniInfoBox
            title={title1}
            shortText={p1}
            logo={<img src="icon-1.png" alt="Logo" />}
          ></MiniInfoBox>
          <br></br>
          <br></br>
          <br></br>
          <MiniInfoBox
            title={title2}
            shortText={p2}
            logo={<img src="icon-2.png" alt="Logo" />}
          ></MiniInfoBox>
          <br></br>
          <br></br>
          <br></br>
          <MiniInfoBox
            title={title3}
            shortText={p3}
            logo={<img src="icon-3.png" alt="Logo" />}
          ></MiniInfoBox>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
};

export default HowtoJoin;
