// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import ErrorBoundary from "./components/Sections/ErrorBoundary";
import { LanguageProvider } from "./components/LanguageContext";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Forum from "./pages/ForumPage";
import Ministers from "./pages/Ministers";
import ScrollToTop from "./components/ScrollToTop";
import LoadingOverlay from "./components/LoadingOverlay";
import TransparecyPage from "./pages/transparencyPage";
function App() {
  const [isLoading, setIsLoading] = useState(false);

  const useRouteChangeLoader = () => {
    const location = useLocation();

    useEffect(() => {
      // Show loading overlay during route changes
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false); // Simulate a loading delay
      }, 1000); // Adjust this delay as needed

      return () => clearTimeout(timer); // Cleanup
    }, [location]);
  };
  return (
    <ErrorBoundary>
      <LanguageProvider>
        <Router>
          <ScrollToTop />
          <LoadingOverlay isLoading={isLoading} />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/ministers" element={<Ministers />} />
            <Route path="/transparency" element={<TransparecyPage />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </ErrorBoundary>
  );
}

export default App;
