import React from "react";
import "../../styles/PhoneSection.css";
import { useLanguage } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import SlideUpWrapper from "../SlideUpWrapper";
const PhoneSection = () => {
  const translations = {
    en: {
      title: "The Application",
      description:
        "This section showcases the app interface with placeholder views for phone images.",
      description1: "",
      description2: "",
      text: "",
    },
    heb: {
      title: "האפליקציה",
      description:
        "במפלגת חזון, אנחנו מחויבים להעניק לכל שותף את הכוח להשפיע על החלטות באופן ישיר. לשם כך, אנו רוצים לפתח אפליקציה ייחודית שתאפשר הצבעה יומיומית בנושאים חשובים שעל סדר היום – מהלך חסר תקדים בשקיפות ובשיתוף הציבור.",
      description1:
        "האפליקציה תאפשר לכל חבר מפלגה להביע את דעתו ולקחת חלק פעיל בקבלת ההחלטות, כך שכל צעד ושעל ייעשה בהתאם לרצון הציבור. באמצעות טכנולוגיה מתקדמת ואבטחת מידע קפדנית, נדאג שהאפליקציה תהיה נגישה, ידידותית ובטוחה לשימוש.",
      description2:
        "חושבים שזה רעיון טוב יש לכם מה לתרום בואו להיות שותפים בדור העתיד ",
      text: "לחץ כאן להצטרפות",
    },
  };
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/contact");
  };
  const { language } = useLanguage();
  const { title, description, description1, description2, text } =
    translations[language];

  return (
    <div className="backround1">
      <div className="content-container">
        <div className="phoneSection">
          <SlideUpWrapper>
            <h1 className="hebrew-header1-W ">{title}</h1>
          </SlideUpWrapper>
          <div className="content-phone">
            <div>
              <SlideUpWrapper>
                <p className="sectionDescription">{description}</p>
              </SlideUpWrapper>
              <SlideUpWrapper>
                <p className="sectionDescription">{description1}</p>
              </SlideUpWrapper>
              <SlideUpWrapper>
                <p className="sectionDescription">{description2}</p>
              </SlideUpWrapper>
              <div className="btn-box">
                <SlideUpWrapper>
                  <Button label={text} onClick={handleNavigation} />
                </SlideUpWrapper>
              </div>
            </div>
            <SlideUpWrapper>
              <div className="phoneContainer">
                <div className="phoneView">
                  <img className="phone" src="/phone1.png" alt="phone1"></img>
                </div>
                <div className="phoneView">
                  <img className="phone" src="/phone2.png" alt="phone2"></img>
                </div>
              </div>
            </SlideUpWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneSection;
