// ContactForm.js
import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import Button from "../Button";
import { useLanguage } from "../LanguageContext";
import "../../styles/ContactForm.css";
import Popup from "../Popup";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [purpose, setPurpose] = useState("");
  const [cellphone, setCellphone] = useState("");
  const translations = {
    en: {
      h: "Get in Touch",
      text: "Have questions or suggestions? We’d love to hear from you! Please fill out the form below, and we’ll get back to you as soon as possible.",
      label1: "Name:",
      label2: "Email:",
      label3: "Message:",
      button: "send",
      popupTitelSuc: "Message sent",
      popupTextSuc: "Message sent to Chzon Team successfully",
      popupTitelFail: "Message not sent",
      popupTextFail: "Oops, there must have been an error, try again...",
    },
    heb: {
      h: "בואו להיות שותפים במפלגת חזון",
      text: "אנחנו מחפשים אתכם – אנשים שמאמינים בשינוי אמיתי ובכוח של שותפות. יחד, נוכל ליצור עתיד טוב יותר לכולנו.",
      text1: "דרושים:",
      text2: "1. אופטימיים שמאמינים בשינוי:",
      text4:
        "אם אתם חולמים על מציאות טובה יותר, אבל מרגישים שכרגע אין לכם את האפשרות להוביל – ברגע שהכל יהיה מוכן, תהיו הראשונים להצטרף ולהשפיע. כבר מההתחלה תוכלו להצביע בכל נושא ולהיות חלק מהתהליך.",
      text5: "2. אופטימיים עם רצון לפעול:",
      text6:
        "אם אתם לא רק חולמים, אלא גם מוכנים להפשיל שרוולים, לדחוף קדימה ולבנות את החזון – זה המקום שלכם. בואו ניצור יחד את העתיד שאנחנו מאמינים בו.",
      text7: "כי יחד, הכוח בידיים שלנו. הצטרפו אלינו עכשיו!",
      label1: "שם:",
      label2: "אימייל:",
      label3: "הודעה:",
      label4: "טלפון:",
      label5: "מטרת הפניה:",
      button: "שלח",
      popupTitelSuc: "הודעה נשלחה",
      popupTextSuc: "ההודעה נשלחה לצוות חזון בהצלחה",
      popupTitelFail: "הודעה לא נשלחה",
      popupTextFail: "אופס כנראה הייתה תקלה תנסה שוב ...",
    },
  };
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleShowPopup = () => setIsPopupVisible(true);
  const handleClosePopup = () => setIsPopupVisible(false);
  const { language } = useLanguage();
  const {
    h,
    text,
    label1,
    label2,
    label3,
    button,
    popupTitelSuc,
    popupTextSuc,
    label4,
    label5,
    popupTitelFail,
    popupTextFail,
    text1,
    text2,
    text4,
    text5,
    text6,
    text7,
  } = translations[language];
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "contacts"), {
        name,
        email,
        message,
        purpose,
        cellphone,
        timestamp: new Date(),
      });
      setName("");
      setEmail("");
      setMessage("");
      setCellphone("");
      setPurpose("");
      handleShowPopup();
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error sending message. Please try again later.");
    }
  };

  return (
    <div className="page">
      <div className="contactForm-container">
        <h2 className="form-title">{h}</h2>
        <br></br>

        <p className="form-description">{text}</p>
        <p className="form-description">{text1}</p>
        <p className="form-description">{text2}</p>
        <p className="form-description">{text4}</p>
        <p className="form-description">{text5}</p>
        <p className="form-description">{text6}</p>
        <p className="form-description">{text7}</p>
        <form onSubmit={handleSubmit} className="contactForm">
          <div className="form-group">
            <label htmlFor="purpose">{label5}</label>
            <select
              id="purpose"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              required
            >
              <option value="">מטרת הפניה</option> {/* Default option */}
              <option value="option1">אופטימיים שמאמינים בשינוי</option>
              <option value="option2">אופטימיים עם רצון לפעול</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name">{label1}</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cellphone">{label4}</label>
            <input
              id="cellphone"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{label2}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">{label3}</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <div className="button-container">
            <Button label={button} onClick={handleSubmit} />
          </div>
        </form>
        {isPopupVisible && (
          <Popup
            title={popupTitelSuc}
            info={popupTextSuc}
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
};

export default ContactForm;
