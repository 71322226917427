import React from "react";
import "../../styles/MethodSection.css";
import { useLanguage } from "../LanguageContext";
import InfoBox from "../InfoBox";

const MethodSection = () => {
  const translations = {
    en: {
      title: "The Mhetod",
      subtitle1: " יפוי כוח",
      subtitle2: "שקיפות",
      description1:
        "This section showcases the app interface with placeholder views for phone images.",
      description2: "damn",
      phoneView: "Phone View",
    },
    heb: {
      title: "שיטה",
      subtitle1: " יפוי כוח",
      subtitle2: "שקיפות",
      subtitle3: "מי ראש המפלגה?",
      subtitle4: "למי שייכת המפלגה?",
      subtitle5: "מה אי אפשר לשנות במפלגת חזון?",
      description1:
        "במפלגת חזון, אנו מבינים שלא כל אחד מעוניין או מסוגל להשתתף בכל הצבעה. כדי לשמור על גמישות וייצוג אמיתי, ניצור מנגנון שמאפשר לשותפים לייפות את כוחו של שותף אחר שיצביע בשמם. כל שותף מיופה כוח יכול להצביע עבור עד עשרה שותפים בלבד, כך שנשמרת האיזון ההוגן בין כל חברי המפלגה.",
      description15:
        "בדרך זו, שותפים שאינם רוצים להיות מעורבים בכל החלטה או מרגישים שאין להם מספיק ידע בנושא מסוים, יכולים לבחור אדם קרוב ואמין – בן משפחה, חבר טוב או כל אדם שיש ביניהם קשר של אמון – שישקף את דעתם וייצג את קולם בצורה הטובה ביותר.",

      description2:
        "במפלגת חזון, השקיפות הכלכלית היא עיקרון יסוד. על המפלגה לפרסם דו''ח הכנסות והוצאות מפורט, שישקף את כל התנועות הכלכליות שלנו עד לרמת הפרטים הקטנים ביותר. אני מאמין כי לכל שותף יש זכות לדעת בדיוק כיצד מנוהלים המשאבים של המפלגה, ומהיכן מגיעים המקורות הכספיים. באמצעות שקיפות זו, נוכל להבטיח שהפעילות הפוליטית שלנו תישאר אמינה ומחוברת לציבור, ללא השפעות חיצוניות ואינטרסים נסתרים.",
      description25:
        "כמו כן, כל נציג במפלגה יהיה מחויב להגיש דוח תקופתי המפרט את פעולותיו למען קידום יוזמות החקיקה של המפלגה. דוח זה ישמש כלי חשוב למדידת פעילותו והערכת תרומתו בפועל להשגת מטרות המפלגה. חובת הדיווח מבטיחה שקיפות מלאה ומעודדת את הנציגים לפעול באחריות ובמסירות, תוך שמירה על מחויבות מתמשכת לקידום האינטרסים של השותפים.",
      description3:
        "כרגע, אני אריה שליידר ראש המפלגה, מתוך מחויבות להוביל את המהלך הראשוני ולגייס 10 שותפים ראשונים. ברגע שנגיע ל-10 שותפים, תתקיימנה בחירות לראש מפלגה חדש. תפקידו יהיה לדחוף את המפלגה להגיע ל-50 שותפים.",
      description4:
        "המפלגה שייכת לשותפיה – כל חברי המפלגה, ללא יוצא מן הכלל, הם בעלי הבית האמיתיים של המפלגה. מעצם היותם שותפים, הם אלו המחזיקים בזכות ובאחריות לקבוע את דרכה של המפלגה, לעצב את מבנה הארגון שלה, ולקבל את כל ההחלטות הנוגעות להתנהלותה. המנגנון הדמוקרטי של המפלגה מבוסס על עקרון ההשתתפות הרחבה, שבו לכל שותף יש קול והשפעה, וההכרעות מתקבלות בהצבעה משותפת. מבנה המפלגה, מנגנוני ההצבעה, ותפקידי הנציגים – כולם כפופים להחלטות השותפים, מתוך ההבנה שהכוח והסמכות נמצאים בידי הציבור הרחב ולא בידי גורם מצומצם. כך מתאפשרת יצירת מבנה שקוף, דמוקרטי והוגן, המשקף את רצון השותפים ומשמר את תחושת האחריות המשותפת על המפלגה כולה.",
      description5:
        "לאחר שנגיע ל-50 שותפים, נקיים שוב בחירות, וראש המפלגה החדש יוביל אותנו לעבר היעד הבא: 100 שותפים. כשנגיע ל-100 שותפים, ייערכו בחירות נוספות, והמטרה הבאה תהיה להגיע ל-1,000 שותפים.",
      description6:
        "לאחר ההגעה ל-1,000 שותפים, יתקיימו בחירות לראש המפלגה שוב, ובשלב זה השותפים יחליטו יחד כל כמה זמן יש לקיים בחירות לראשות המפלגה. כך נבטיח דמוקרטיה חיה, משתפת ומתחדשת לאורך הדרך.",
      description7:
        "במפלגת חזון אפשר לשנות הכל, כי המפלגה מתנהלת בדמוקרטיה מלאה וכל החלטה מתקבלת ברוב דעות של השותפים. אבל יש עקרון בסיסי שאי אפשר לשנות:המפלגה מתנהלת בדמוקרטיה ובהסכמה על פי רוב. כל שותף שווה ערך, וכולם משפיעים.",
    },
  };

  const { language } = useLanguage();
  const {
    title,
    description1,
    description2,
    subtitle1,
    subtitle2,
    subtitle3,
    subtitle4,
    subtitle5,
    description3,
    description4,
    description5,
    description6,
    description25,
    description15,
    description7,
  } = translations[language];

  return (
    <div className="backround1">
      <div className="content-container">
        <div className="methodSection1">
          <div className="header-container">
            <h1 className="hebrew-header1-W">{title}</h1>
            <br></br>
            <br></br>
          </div>
          <div className="parallelBoxes">
            <InfoBox title={subtitle4} paragraphs={[description4]} />
            <InfoBox title={subtitle5} paragraphs={[description7]} />
            {/* <InfoBox
            title={subtitle2}
            paragraphs={[description2, description25]}
          /> */}
            {/* <InfoBox
            title={subtitle3}
            paragraphs={[description3, description5, description6]}
          /> */}
          </div>
          <div className="parallelBoxes">
            <InfoBox
              title={subtitle3}
              paragraphs={[description3, description5, description6]}
            />
            <InfoBox
              title={subtitle2}
              paragraphs={[description2, description25]}
            />
          </div>
          <div className="boxContainer">
            <InfoBox
              title={subtitle1}
              paragraphs={[description1, description15]}
            />
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default MethodSection;
