import React, { useEffect, useState } from "react";
import { getCommentsByCard } from "./firebase";
import "../styles/Comments.css";
import { useLanguage } from "./LanguageContext";
const Comments = ({ cardId }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      const fetchedComments = await getCommentsByCard(cardId);
      setComments(fetchedComments);
    };

    fetchComments();
  }, [cardId]);
  const translations = {
    en: {
      title: "Comments",
    },
    heb: {
      title: "תגובות",
    },
  };

  const { language } = useLanguage();
  const { title } = translations[language];
  return (
    <div className="commentsSection">
      {comments.map((comment) => (
        <div key={comment.id} className="comment">
          <p>
            <strong>{comment.name}:</strong> {comment.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Comments;
