import React from "react";
import Layout from "../components/Sections/Layout";
import { useLanguage } from "../components/LanguageContext";
import "../styles/TextPage.css";
function Ministers() {
  const translations = {
    en: {
      bigHeader: "Our Ministers",
      paragraph1:
        "Our ministers are dedicated to serving the public and driving progress.",
      smallHeader1: "Minister of Innovation",
      paragraph2:
        "Leads groundbreaking projects to foster innovation and technological growth.",
      smallHeader2: "Minister of Education",
      paragraph3:
        "Focuses on creating accessible, quality education for all citizens.",
      smallHeader3: "Minister of Health",
      paragraph4:
        "Committed to improving healthcare services and public health initiatives.",
      smallHeader4: "Minister of Finance",
      paragraph5:
        "Oversees economic policies to ensure sustainable development.",
      paragraph6:
        "Each minister brings unique skills and expertise to their role.",
      paragraph7:
        "Together, they form a team committed to transparency and accountability.",
      paragraph8: "",
    },
    heb: {
      bigHeader: "שרים ",
      paragraph1:
        "במפלגת חזון הכוח נשאר בידי האדם הפשוט ומגיע מלמטה ללמעלה כל שותף יכול להציע חקיקה חדשה כל זמן מסויים. כל חקיקה חדשה תצטרך לעבור את האזרח הפשוט, את שרי העשרות, החמישים, המאות, האלפים ואז את כל החברים:",
      smallHeader1: "שרי עשרות",
      paragraph2:
        "כל עשרה שותפים במפלגה מתקבצים ליחידה אחת ובראשה נציג - שר עשרות. שר העשרות מחזיק בידיו כוח של שני מצביעים אבל רק בהצבעות 'בעד'. בהצבעות 'נגד' הוא נספר כמו מצביע רגיל.",
      smallHeader2: "שרי חמישים",
      paragraph3:
        "כל חמש קבוצות של עשרה שותפים מתקבצים ליחידה אחת ובראשה נציג - שר חמישים. שר החמישים מחזיק בידיו כוח של חמשה מצביעים אבל רק בהצבעות 'בעד'. בהצבעות 'נגד' הוא נספר כמו מצביע רגיל.",
      smallHeader3: "שרי מאות",
      paragraph4:
        "כל שתי קבוצות של חמישים שותפים מתקבצים ליחידה אחת ובראשה נציג - שר מאות. שר המאות מחזיק בידיו כוח של עשרה מצביעים אבל רק בהצבעות 'בעד'. בהצבעות 'נגד' הוא נספר כמו מצביע רגיל.",
      smallHeader4: "שרי אלפים",
      paragraph5:
        "כל עשר קבוצות של מאה שותפים מתקבצים ליחידה אחת ובראשה נציג - שר אלפים. שר האלפים מחזיק בידיו כוח של מאה מצביעים אבל רק בהצבעות 'בעד'. בהצבעות 'נגד' הוא נספר כמו מצביע רגיל",
      paragraph6:
        "על מנת שחקיקה חדשה תוכל לעבור היא צריכה לעבור את כל שרשרת הקבוצות, החל מהעשרות וכלה באלפים, ואז גם לעבור הצבעה נוספת של כל השותפים, ובכך אנחנו מוודאים שרצון העם באמת מתקיים ומגיע מלמטה ללמעלה והכוח לא נמצא רק בידי הנבחרים.",
      paragraph7:
        "לדוגמא: אם הוצע חוק שיאסור על שימוש בטלפונים סלולריים בזמן העבודה לעובדי מדינה, הוא צריך לעבור קודם הצבעה בקבוצת העשרות של המציע ואז, במידה והחוק עבר, הוא צריך לעבור גם אצל שרי החמישים, המאות והאלפים. ואז, אם החוק עבר אצל כולם, נערכת הצבעה נוספת בפני כל השותפים. במידה וגם כאן יש רוב, צריך להתקיים דיון של הנבחרים עם זה שהציע את החוק ובתוך מסגרת זמן קבועה מראש צריך לצאת דוח לשותפים שיפרט את תוכנית הפעולה לקידום החוק החדש.",
      paragraph8:
        "המבנה ההיררכי נועד להבטיח כי כל רעיון, מכל קבוצה במפלגה, יזכה לייצוג הולם ולטיפול מעמיק. מנגנון זה פועל בצורה מדורגת, כך שהוא מאפשר סינון ראשוני של הרעיונות, ובסופו של תהליך, מציג בפני השותפים את הרעיונות המגובשים והבשלים ביותר. בדרך זו נשמר שיתוף פעולה הדוק בין הקבוצות, תוך עידוד צמיחה משותפת והעלאת רעיונות איכותיים שזוכים לדיון מעמיק ולקידום ראוי.",
    },
  };

  const { language } = useLanguage();
  const {
    bigHeader,
    paragraph1,
    smallHeader1,
    paragraph2,
    smallHeader2,
    paragraph3,
    smallHeader3,
    paragraph4,
    smallHeader4,
    paragraph5,
    paragraph6,
    paragraph7,
    paragraph8,
  } = translations[language];
  return (
    <Layout>
      <div className="backround">
        <div className={`page ${language === "heb" ? "rtl" : "ltr"}`}>
          <h1 className="big-header">{bigHeader}</h1>
          <p className="paragraph">{paragraph1}</p>
          <h2 className="small-header">{smallHeader1}</h2>
          <p className="paragraph">{paragraph2}</p>
          <h2 className="small-header">{smallHeader2}</h2>
          <p className="paragraph">{paragraph3}</p>
          <h2 className="small-header">{smallHeader3}</h2>
          <p className="paragraph">{paragraph4}</p>
          <h2 className="small-header">{smallHeader4}</h2>
          <p className="paragraph">{paragraph5}</p>
          <p className="paragraph">{paragraph6}</p>
          <p className="paragraph">{paragraph7}</p>
          <p className="paragraph">{paragraph8}</p>
        </div>
      </div>
    </Layout>
  );
}

export default Ministers;
