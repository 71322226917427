import React from "react";
import Layout from "../components/Sections/Layout";
import { useLanguage } from "../components/LanguageContext";
const TransparecyPage = () => {
  const { language } = useLanguage();
  return (
    <Layout>
      <div className="backround">
        <div className={`page ${language === "heb" ? "rtl" : "ltr"}`}>
          <h1 className="big-header">שקיפות כלכלית</h1>
          <br></br>
          <br></br>
          <br></br>
          <img className="table" src="/trans.jpg" alt="Description" />
        </div>
      </div>
    </Layout>
  );
};
export default TransparecyPage;
