import React from "react";
import MiniInfoBox from "../MiniInfoBox";
import { useLanguage } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import "../../styles/TenCommandmentSection.css";
import SlideUpWrapper from "../SlideUpWrapper";
import SlideInWrapper from "../SlideInWrapper";
const TenCommandments = () => {
  const translations = {
    en: {
      title: "The Ten Commandments",
      title1: "1",
      text1: "1",
      title7: "",
      text8: "",
    },
    heb: {
      title: "עשרת הדיברות",
      title1: "שקיפות",
      text1:
        "שקיפות מלאה בכל תהליך קבלת ההחלטות, בשימוש בכספי ציבור ובפעילות המפלגה, כדי להבטיח אמון ציבורי ולהעניק לאזרחים תמונה ברורה של הנעשה.",
      title2: "אחוז גברים ונשים",
      text2:
        "שמירה על ייצוג שוויוני בין גברים לנשים ברשימת המפלגה, מתוך אמונה ששוויון מגדרי הוא תנאי להנהגה מגוונת וצודקת.",
      title3: "נבחר צעיר",
      text3:
        "עידוד שילוב נציגים צעירים ברשימה, המביאים עימם חשיבה חדשנית ואנרגיה רעננה, שתורמת להתחדשות הפוליטיקה. מחוברים לדור היותר צעיר שמתמודד עם בעיות שונות, בנוסף אנו רוצים ליצור דור חדש של מנהיגים חזקים ומנוסים.",
      title4: "לשון מכובדת",
      text4:
        "מחויבות לשיח מכבד ולשפה נקייה, מתוך רצון לקדם פוליטיקה שמעמידה דוגמה אישית ומתמקדת בדיון ענייני.",
      title5: "לשבת בממשלה",
      text5:
        "שאיפה להשפיע מבפנים על קבלת ההחלטות באמצעות ישיבה בממשלה, מתוך מטרה לקדם את האג'נדות. לחשוב יותר על המטרה הגדולה לקדם כאן ועכשיו ולא לנטור טינה פוליטית שלא מקדמת אותנו לשום מקום.",
      title6: "רוצים להוסיף עקרונות נוספים?",
      text6:
        "אנו מאמינים שפוליטיקה טובה צומחת מתוך שיח פתוח ושיתוף פעולה. אם יש לכם רעיונות לעקרונות נוספים בואו תהיו שותפים. יחד נוכל לבנות עתיד טוב יותר לכולנו.",
      text7: "לחץ כאן ליצירת קשר",
      title7: "והדרת פני זקן",
      text8:
        "נתינת במה משמעותית לחוקים הקשורים לבני הגיל השלישי, הדור שבנה את המדינה, עבד קשה כל חייו ותרם לחברה. דאגה להם היא לא רק חובה מוסרית – היא ערך יסוד שמוביל את המפלגה.",
    },
  };

  const { language } = useLanguage();
  const {
    title,
    title1,
    text1,
    title2,
    text2,
    title3,
    text3,
    title4,
    text4,
    title5,
    text5,
    title6,
    text6,
    text7,
    text8,
    title7,
  } = translations[language];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/contact");
  };
  return (
    <div className="backround">
      <div className="content-container">
        <div className="TenCommandmentSection">
          <SlideUpWrapper>
            <h1 className="hebrew-header1-W">{title}</h1>
          </SlideUpWrapper>
          <br></br>
          <br></br>
          <div className="row-commandment">
            <SlideInWrapper direction="right">
              <MiniInfoBox
                title={title7}
                shortText={text8}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
            <br></br>
            <SlideInWrapper direction="left">
              <MiniInfoBox
                title={title5}
                shortText={text5}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
          </div>
          <br></br>
          <div className="row-commandment">
            <SlideInWrapper direction="right">
              <MiniInfoBox
                title={title4}
                shortText={text4}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
            <br></br>
            <SlideInWrapper direction="left">
              <MiniInfoBox
                title={title3}
                shortText={text3}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
          </div>
          <br></br>
          <div className="row-commandment">
            <SlideInWrapper direction="right">
              <MiniInfoBox
                title={title1}
                shortText={text1}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
            <br></br>
            <SlideInWrapper direction="left">
              <MiniInfoBox
                title={title2}
                shortText={text2}
                logo={<img src="file.png" alt="Logo" />}
              />
            </SlideInWrapper>
          </div>
          <br></br>
          <div className="row-commandment">
            <SlideInWrapper direction="right">
              <MiniInfoBox logo={<img src="file.png" alt="Logo" />} />
            </SlideInWrapper>
            <br></br>
            <SlideInWrapper direction="left">
              <MiniInfoBox logo={<img src="file.png" alt="Logo" />} />
            </SlideInWrapper>
          </div>
          <br></br>
          <div className="row-commandment">
            <SlideInWrapper direction="right">
              <MiniInfoBox logo={<img src="file.png" alt="Logo" />} />
            </SlideInWrapper>
            <br></br>
            <SlideInWrapper direction="left">
              <MiniInfoBox logo={<img src="file.png" alt="Logo" />} />
            </SlideInWrapper>
          </div>
          <br></br>
          <SlideInWrapper direction="right">
            <MiniInfoBox
              title={title6}
              shortText={text6}
              logo={<img src="file.png" alt="Logo" />}
              ButtonComponent={
                <Button label={text7} onClick={handleNavigation} />
              }
            />
          </SlideInWrapper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default TenCommandments;
